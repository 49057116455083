<template>
  <van-popup
    v-model="isShow"
    overlay-class="overlay"
    @close="itemclose"
    :close-on-click-overlay="false"
  >
    <div v-if="isCard" class="boxc">
      <van-image
        width="264px"
        height="127px"
        :src="require('@/assets/jys_kqs.png')"
      />
      <!-- 购买确认中,请稍后... -->
      <p
        class="fs-md t-color5 text t-center"
        :class="lang == 'zh' ? 'text2' : 'text'"
      >
        {{ $t("tip.tip5") }}
      </p>
    </div>
    <div v-if="isMang">
      <van-image
        width="218px"
        height="252px"
        :src="require('@/assets/manghe.png')"
      />
      <!-- 抽取中，请等待... -->
      <p class="fs-lg t-center">{{ $t("tip.tip3") }}</p>
    </div>
    <div v-if="isMangClick">
      <van-image
        width="218px"
        height="252px"
        :class="isAnimation ? 'swing' : ''"
        :src="require('@/assets/manghe.png')"
        @click="extract"
      />
      <!-- 请点击盲盒进行抽取 -->
      <p class="fs-lg t-center blink">{{ $t("tip.tip2") }}</p>
    </div>
    <div class="d-flex ai-center jc-center m-t-30">
      <van-image
        width="21px"
        height="21px"
        :src="require('@/assets/cancel@2x.png')"
        @click="close"
      />
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    isShow: { type: Boolean, default: false },
    isCard: { type: Boolean, default: false }, //卡牌购买确认中
    isMang: { type: Boolean, default: false }, //盲盒抽取确认中
    isMangClick: { type: Boolean, default: false }, //是否是点击盲盒抽取
  },
  data() {
    return {
      isAnimation: false,
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  methods: {
    itemclose(){
      this.isAnimation = false;
    },
    close() {
      this.$emit("close");
    },
    //点击盲盒抽取
    extract() {
      this.isAnimation = true;
      this.$emit("extract");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.boxc {
  position: relative;
  .text {
    position: absolute;
    bottom: 20px;
    right: 5%;
    // transform: translateX(-25%);
  }
  .text2 {
    position: absolute;
    bottom: 20px;
    left: 30%;
    right: auto;
    letter-spacing: 1px;
    // transform: translateX(-25%);
  }
}
</style>