<template>
  <div class="containers">
    <navbar></navbar>
    <div class="contents px-40 py-15">
      <div class="bg-color6 main d-flex flex-column ai-center jc-center">
        <div class="m-b-25 logoimg">
          <van-image
            width="153px"
            height="203px"
            v-if="cardDetail.level"
            :src="require('@/assets/gif/border' + cardDetail.level + '.png')"
          />
          <van-image
            width="153px"
            height="203px"
            class="img"
            v-if="cardDetail.cardInfo"
            :src="cardDetail.cardInfo.gif_remote_url"
          />
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("store.type") }}</p>
          <p class="bold">{{ $t("store.cards") }}</p>
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("store.name") }}</p>
          <p class="bold" v-if="cardDetail.cardInfo">
            {{
              lang == "zh"
                ? cardDetail.cardInfo.name
                : cardDetail.cardInfo.en_name
            }}
          </p>
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("store.star") }}</p>
          <p class="bold">{{ $t("level.level" + cardDetail.level) }}</p>
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("store.token") }}</p>
          <p class="bold">{{ cardDetail.tokenid }}</p>
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("store.price") }}</p>
          <p class="bold">
            <span class="fs-xxxl t-color5">{{
              cardDetail.price | cutZero
            }}</span>
            BNB
          </p>
        </div>
        <div class="d-flex ai-center jc-between m-b-20 w-100">
          <p class="t-color10">{{ $t("store.user") }}</p>
          <p class="bold" v-if="cardDetail.seller">
            {{ cardDetail.seller.address | plusXing(7, 4) }}
          </p>
        </div>
        <p class="fs-sm m-t-40">
          {{ $t("store.banlece") }}: {{ balance | Fixed(4) }} BNB
        </p>
        <van-button
          color="#074CE7"
          class="bold btn"
          @click="onBuy"
          :loading="loading"
          >{{ $t("store.buynow") }}</van-button
        >
      </div>
    </div>
    <!-- 卡牌购买确认中 -->
    <Confirming :isShow="isShow" :isCard="true"></Confirming>
    <!-- 获得卡牌 -->
    <GetSuc
      :isShow="cardDetail.cardInfo && isGet"
      :isCard="true"
      :level="cardDetail.level"
      :txhash="txHash"
      :cardId="cardDetail.card_id"
      :imgUrl="cardDetail.cardInfo && cardDetail.cardInfo.gif_remote_url"
      @close="onClose"
    ></GetSuc>
  </div>
</template>

<script>
import Confirming from "@/components/common/confirming";
import GetSuc from "@/components/common/getSuc";
import { mapState } from "vuex";
import BigNumber from "bignumber.js";
import abi_C from "../common/abi/abi_C.json";
export default {
  components: { Confirming, GetSuc },
  data() {
    return {
      isShow: false,
      isGet: false,
      loading: false,
      cardDetail: {},
      receive_address: "", //收币地址
      contract_address: "", //合约地址
      txHash: "", //交易哈希
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
    ...mapState({
      balance: (state) => state.balance,
      walletAddress: (state) => state.walletAddress,
      setting: (state) => state.setting,
    }),
  },
  created() {
    this.$store.dispatch("getBalance"); //钱包余额
    this.$store.dispatch("getSetting"); //网站配置
  },
  mounted() {
    this.cardDetail = JSON.parse(this.$route.query.value);
    this.getSet();
  },
  methods: {
    //获取网站配置
    getSet() {
      let set = JSON.parse(this.setting);
      if (set) {
        set.forEach((element) => {
          if (element.id == 3) {
            //盲盒抽卡收币地址
            this.receive_address = element.value;
          } else if (element.id == 2) {
            //卡牌合约
            this.contract_address = element.value;
          }
        });
      }
    },
    //买卡片 预备
    cardbuyBegin() {
      this.loading = true;
      this.$axios
        .post(this.$api.cardbuy + "/" + this.cardDetail.id, {})
        .then((res) => {
          if (res.code == 200) {
            this.buycard();
          } else {
            this.loading = false;
            this.$toast(res.msg);
          }
        });
    },
    //买卡片
    buycard() {
      let myContract = new web3.eth.Contract(abi_C, this.contract_address);
      let v = this.cardDetail.price * Math.pow(10, 18);
      let price =
        "0x" +
        BigNumber(v)
          .toString(16)
          .replace(/\.\w+$/, "");
      myContract.methods
        .bid(this.cardDetail.tokenid)
        .send({ from: this.walletAddress, value: price }, (err, res) => {
          this.$store.dispatch("getBalance");
          if (res) {
            return this.cardbuyBack(res, res ? 1 : 2, res ? "OK" : err.message);
          }
          if (err) {
            this.loading = false;
            return this.$toast(err.message);
          }
        });
      // .on("error", function (err) {
      //   console.log(err);
      // });
    },
    // 买卡片 回调
    cardbuyBack(hash, status, msg) {
      this.isShow = !this.isShow;
      this.$axios
        .post(this.$api.cardbuyback + "/" + this.cardDetail.id, {
          status: status, //0=失败，1=成功，2=用户拒绝
          txhash: hash,
          msg: msg,
        })
        .then((res) => {
          this.isShow = false;
          if (res.code == 200) {
            this.txhash = hash;
            this.loading = false;
            this.isGet = true;
          } else {
            this.loading = false;
            this.$toast(res.msg);
          }
          setTimeout(() => {
            this.$store.dispatch("getBalance");
          }, 1000);
        });
    },
    onClose() {
      this.isGet = false;
    },
    onBuy() {
      if (this.balance <= this.cardDetail.price) {
        this.$toast(this.$t("public.noblance"));
      } else {
        this.cardbuyBegin();
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
.main {
  border-radius: 10px;
  padding: 18px 16px;
  .logoimg {
    position: relative;
    .img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.btn {
  width: 100%;
  border-radius: 10px;
  height: 35px;
  margin-top: 10px;
}
</style>