<template>
  <van-popup v-model="isShow" overlay-class="overlay">
    <div class="main d-flex flex-column ai-center jc-center">
      <div class="imglogo">
        <van-image
          width="153px"
          height="210px"
          :src="require('@/assets/gif/border' + level + '.png')"
        />
        <van-image width="153px" height="210px" class="img p-5" :src="imgUrl" />
        <!-- <van-image
          width="153px"
          height="210px"
          class="img"
          :src="require('@/assets/gif/LV' + level + '.gif')"
        /> -->
      </div>
      <p class="bold lh-3 m-t-30 t-center" v-if="isCard">
        <!-- 您已购买 -->
        {{ $t("tip.tip6") }}<br />
        {{ txhash }} !
      </p>
      <p class="bold lh-3 m-t-30 t-center" v-else>
        {{ $t("tip.congratulations") }} !<br />
        <span v-if="isbuy">{{ $t("tip.tip4") }}</span
        ><span v-else>{{ $t("tip.tip11") }}</span>
        {{ $t("level.level" + level) }} - {{ name }} !
      </p>
      <div class="m-t-20">
        <!-- 进入卡包 -->
        <van-button
          plain
          class="btn plain t-white borders"
          @click="toMynftcard"
        >
          <span class="mx-10 fs-md"> {{ $t("blindBox.cardpack") }}</span>
        </van-button>
        <!-- 查看详情 -->
        <van-button color="#074CE7" class="btn" @click="tomyCardDetail"
          ><span class="fs-md">{{
            $t("blindBox.viewDetail")
          }}</span></van-button
        >
      </div>
    </div>
    <div class="d-flex ai-center jc-center m-t-30">
      <van-image
        width="21px"
        height="21px"
        :src="require('@/assets/cancel@2x.png')"
        @click="close"
      />
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    isShow: { type: Boolean, default: false },
    isCard: { type: Boolean, default: true },
    isbuy: { type: Boolean, default: true },
    level: { type: Number, default: 1 },
    txhash: { type: String, default: "" },
    name: { type: String, default: "盲盒名称" },
    imgUrl: { type: String, default: "" },
    cardId: { type: Number, default: null },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("close");
    },
    //我的NFT卡牌
    toMynftcard() {
      this.$router.push("/myNFTcard");
    },
    //我的卡牌详情
    tomyCardDetail() {
      this.$router.push({
        path: "/mycardDetail",
        query: { id: Number(this.cardId) },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.van-popup {
  background: none;
}
.main {
  width: 100vw;
  height: 430px;
  background: url("~@/assets/guang.png") no-repeat;
  background-size: 100% 100%;
  .imglogo {
    margin-top: 130px;
    position: relative;
    .img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .btn {
    min-width: 120px;
    height: 30px;
    border-radius: 10px;
  }
  .plain {
    padding: 0;
    background-color: transparent;
    margin-right: 10px;
    border-image: linear-gradient(139deg, #15a3ee, #391aeb);
  }
}
</style>